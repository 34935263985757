import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
import { HashLink, InternalLink } from '../components/link';
import asyncFollowerImg from '../images/designing-data-intensive-apps/async-follower.png';
import bTreeImg from '../images/designing-data-intensive-apps/b-tree-diagram.png';
import consistentPrefixImg from '../images/designing-data-intensive-apps/bug-without-consistent-prefix-reads.png';
import monotonicReadsImg from '../images/designing-data-intensive-apps/bug-without-monotonic-reads.png';
import etlImg from '../images/designing-data-intensive-apps/etl.png';
import multiLeaderImg from '../images/designing-data-intensive-apps/multi-leader-replication.png';
import oltpVsOlapImg from '../images/designing-data-intensive-apps/oltp-vs-olap.png';
import singleLeaderImg from '../images/designing-data-intensive-apps/single-leader-replication.png';
import starSchemaImg from '../images/designing-data-intensive-apps/star-schema.png';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Designing Data-Intensive Applications`}</h1>
    <p>{`Part 1: Foundation of Data systems`}</p>
    <ul>
      <li parentName="ul">{`Chapter 1: Reliable, Scalable, and Maintainable Applications`}</li>
      <li parentName="ul">
        <InternalLink href="/data-intensive-apps#chapter-2" mdxType="InternalLink">
  Chapter 2: Data Models and Query Languages
        </InternalLink>
      </li>
      <li parentName="ul">
        <InternalLink href="/data-intensive-apps#chapter-3" mdxType="InternalLink">
  Chapter 3: Storage and Retrieval
        </InternalLink>
      </li>
      <li parentName="ul">
        <InternalLink href="/data-intensive-apps#chapter-4" mdxType="InternalLink">
  Chapter 4: Encoding and Evolution
        </InternalLink>
      </li>
    </ul>
    <InternalLink href="/data-intensive-apps#part-2" mdxType="InternalLink">
  Part 2: Distributed Data
    </InternalLink>
    <ul>
      <li parentName="ul">
        <InternalLink href="/data-intensive-apps#chapter-5" mdxType="InternalLink">
  Chapter 5: Replication
        </InternalLink>
      </li>
      <li parentName="ul">
        <InternalLink href="/data-intensive-apps#chapter-6" mdxType="InternalLink">
  Chapter 6: Partitioning
        </InternalLink>
      </li>
      <li parentName="ul">
        <InternalLink href="/data-intensive-apps#chapter-7" mdxType="InternalLink">
  Chapter 7: Transactions
        </InternalLink>
      </li>
      <li parentName="ul">
        <InternalLink href="/data-intensive-apps#chapter-8" mdxType="InternalLink">
  Chapter 8: The Trouble with Distributed Systems
        </InternalLink>
      </li>
      <li parentName="ul">
        <InternalLink href="/data-intensive-apps#chapter-9" mdxType="InternalLink">
  Chapter 9: Consistency and Consensus
        </InternalLink>
      </li>
    </ul>
    <InternalLink href="/data-intensive-apps#part-3" mdxType="InternalLink">
  Part 3: Derived Data
    </InternalLink>
    <ul>
      <li parentName="ul">
        <InternalLink href="/data-intensive-apps#chapter-10" mdxType="InternalLink">
  Chapter 10: Batch Processing
        </InternalLink>
      </li>
      <li parentName="ul">
        <InternalLink href="/data-intensive-apps#chapter-11" mdxType="InternalLink">
  Chapter 11: Stream Processing
        </InternalLink>
      </li>
      <li parentName="ul">
        <InternalLink href="/data-intensive-apps#chapter-12" mdxType="InternalLink">
  Chapter 12: The Future of Data Systems
        </InternalLink>
      </li>
    </ul>
    <h2><HashLink id="chapter-2" to="/data-intensive-apps#chapter-2" mdxType="HashLink">{`Chapter 2: Data Models and Query Languages`}</HashLink></h2>
    <p>{`Reasons to adopt NoSQL over relational data stores:`}</p>
    <ul>
      <li parentName="ul">{`A need for greater scalability than relational databases can easily achieve, including very large datasets or very high write throughput`}</li>
      <li parentName="ul">{`A widespread preference for free and open source software over commercial database products`}</li>
      <li parentName="ul">{`Specialized query operations that are not well supported by the relational model`}</li>
      <li parentName="ul">{`Frustration with the restrictiveness of relational schemas, and a desire for a more dynamic and expressive data model`}</li>
    </ul>
    <p>{`With document stores, the work of making joins is shifted from the db to the app code`}</p>
    <p><em parentName="p">{`Impedance mismatch`}</em>{` is the disconnect between object in app code and the db model of tables. ORMs help but they can’t completely hide the differences between the two models.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Historically, data started out being represented as one big tree (the hierarchical model), but that wasn’t good for representing many-to-many relationships, so the relational model was invented to solve that problem. More recently, developers found that some applications don’t fit well in the relational model either. New nonrelational “NoSQL” datastores have diverged in two main directions:`}</p>
      <ol parentName="blockquote">
        <li parentName="ol">{`Document databases target use cases where data comes in self-contained documents and relationships between one document and another are rare.`}</li>
        <li parentName="ol">{`Graph databases go in the opposite direction, targeting use cases where anything is potentially related to everything.`}</li>
      </ol>
      <p parentName="blockquote">{`All three models (document, relational, and graph) are widely used today, and each is good in its respective domain. One model can be emulated in terms of another model—for example, graph data can be represented in a relational database—but the result is often awkward. That’s why we have different systems for different purposes, not a single one-size-fits-all solution.`}</p>
      <p parentName="blockquote">{`One thing that document and graph databases have in common is that they typically don’t enforce a schema for the data they store, which can make it easier to adapt applications to changing requirements. However, your application most likely still assumes that data has a certain structure; it’s just a question of whether the schema is explicit (enforced on write) or implicit (assumed on read).`}</p>
    </blockquote>
    <h2><HashLink id="chapter-3" to="/data-intensive-apps#chapter-3" mdxType="HashLink">{`Chapter 3: Storage and Retrieval`}</HashLink></h2>
    <blockquote>
      <p parentName="blockquote">{`Why should you, as an application developer, care how the database handles storage and retrieval internally? You’re probably not going to implement your own storage engine from scratch, but you `}<em parentName="p">{`do`}</em>{` need to select a storage engine that is appropriate for your application, from the many that are available. In order to tune a storage engine to perform well on your kind of workload, you need to have a rough idea of what the storage engine is doing under the hood.`}</p>
      <p parentName="blockquote">{`In particular, there is a big difference between storage engines that are optimized for transactional workloads and those that are optimized for analytics.`}</p>
    </blockquote>
    <p>{`OLTP - Online Transaction Processing
OLAP - Online Analytics Processing`}</p>
    <p>{`OLTP vs OLAP`}</p>
    <div style={{
      "display": "flex",
      "justifyContent": "center"
    }}>
  <img src={oltpVsOlapImg} alt="OLTP comparison with OLAP" width="100%" />
    </div>
    <p>{`Disk `}<em parentName="p">{`seek time`}</em>{` is often the biggest bottleneck when using OLTP databases.
Disk `}<em parentName="p">{`bandwidth`}</em>{` is often the biggest bottleneck when using OLAP databases.`}</p>
    <h3><HashLink id="oltp" to="/data-intensive-apps#oltp" mdxType="HashLink">{`OLTP`}</HashLink></h3>
    <h4><HashLink id="storage-engines" to="/data-intensive-apps#storage-engines" mdxType="HashLink">{`Storage engines`}</HashLink></h4>
    <p><strong parentName="p">{`Log structured`}</strong></p>
    <p>{`This storage strategy only permits appending to files and deleting obsolete files. A file that has been written is never updated.`}</p>
    <p>{`Sequential writes on disk enables higher write throughput compared to random-access writes.`}</p>
    <p>{`Examples:`}</p>
    <ul>
      <li parentName="ul">{`SSTables`}</li>
      <li parentName="ul">{`LSM-trees`}</li>
      <li parentName="ul">{`LevelDB`}</li>
      <li parentName="ul">{`Cassandra`}</li>
      <li parentName="ul">{`HBase`}</li>
      <li parentName="ul">{`Lucene`}</li>
      <li parentName="ul">{`Bitcask`}</li>
    </ul>
    <p><strong parentName="p">{`Update-in-place`}</strong></p>
    <p>{`Treat the disk as a set of fix-sized pages that can be overwritten.`}</p>
    <p>{`B-trees are the most common example of this philosophy.`}</p>
    <h4><HashLink id="db-indexes" to="/data-intensive-apps#db-indexes" mdxType="HashLink">{`Database indexes`}</HashLink></h4>
    <blockquote>
      <p parentName="blockquote">{`An index is an `}<em parentName="p">{`additional`}</em>{` structure that is derived from the primary data. Many databases allow you to add and remove indexes, and this doesn’t affect the contents of the database; it only affects the performance of queries.`}</p>
    </blockquote>
    <p>{`A big downside to using indexes is the overhead incurred on write operations. It's hard to beat the performance of simply appending to a file because that’s the simplest possible write operation. The index needs to be updated every time data is written which slows down writes.`}</p>
    <p>{`B-trees are the most widely used indexing structure.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Introduced in 1970 and called “ubiquitous” less than 10 years later, B-trees have stood the test of time very well. They remain the standard index implementation in almost all relational databases, and many nonrelational databases use them too.`}</p>
    </blockquote>
    <p>{`B-tree diagram:`}</p>
    <div style={{
      "display": "flex",
      "justifyContent": "center"
    }}>
  <img src={bTreeImg} alt="B-tree Diagram" width="100%" />
    </div>
    <h4><HashLink id="in-memory-db" to="/data-intensive-apps#in-memory-db" mdxType="HashLink">{`In-memory databases`}</HashLink></h4>
    <blockquote>
      <p parentName="blockquote">{`Counterintuitively, the performance advantage of in-memory databases is not due to the fact that they don’t need to read from disk. Even a disk-based storage engine may never need to read from disk if you have enough memory, because the operating system caches recently used disk blocks in memory anyway. Rather, they can be faster because they can avoid the overheads of encoding in-memory data structures in a form that can be written to disk.`}</p>
    </blockquote>
    <h3><HashLink id="olap" to="/data-intensive-apps#olap" mdxType="HashLink">{`OLAP`}</HashLink></h3>
    <p>{`OLTP databases usually aren't used for business analysts to run ad hoc analytics queries.`}</p>
    <p>{`These queries are often expensive (scanning large parts of the dataset) which can harm the performance of concurrently executing transactions. Separate "data warehouse" databases are for running these analytics queries.`}</p>
    <p>{`ETL jobs will get data dumps or data streams from OLTP databases, massage the data into an analysis-friendly schema and store the data in the warehouse.`}</p>
    <div style={{
      "display": "flex",
      "justifyContent": "center"
    }}>
  <img src={etlImg} alt="ETL diagram" width="100%" />
    </div>
    <h4><HashLink id="warehousing" to="/data-intensive-apps#warehousing" mdxType="HashLink">{`Data models for data warehousing`}</HashLink></h4>
    <p><strong parentName="p">{`Star schema aka dimensional modeling`}</strong></p>
    <div style={{
      "display": "flex",
      "justifyContent": "center"
    }}>
  <img src={starSchemaImg} alt="Star schema figure" width="100%" />
    </div>
    <p>{`Each row of a fact table represents an event. Dimensions represent the who, what , when, where, why and how of the event.`}</p>
    <p><strong parentName="p">{`Snowflake schema`}</strong>{`
Similar to a star schema but dimensions are broken down into sub-dimensions. Snowflake schemas are more normalized than star schemas, but star schemas are often preferred because they are simpler for analysts to work with.`}</p>
    <p>{`Regardless of schema design, most OLAP databases leverage `}<em parentName="p">{`column-oriented storage`}</em>{`. The idea is to store all the values from each `}<em parentName="p">{`column`}</em>{` together instead of storing all the values from each `}<em parentName="p">{`row`}</em>{`. This makes queries more efficient for tables with hundreds of columns that may not be necessary to process in order to fulfill the query.`}</p>
    <p>{`There are also column-oriented compression and sorting techniques used to improve read performance. LSM-trees are commonly used for write operations.`}</p>
    <h2><HashLink id="chapter-4" to="/data-intensive-apps#chapter-4" mdxType="HashLink">{`Chapter 4: Encoding and Evolution`}</HashLink></h2>
    <p>{`With a bit of care, backward/forward compatibility and rolling upgrades are very much achievable.`}</p>
    <h3><HashLink id="encoding" to="/data-intensive-apps#encoding" mdxType="HashLink">{`Encoding`}</HashLink></h3>
    <p>{`Whenever you want to send some data to another process with which you don’t share memory (e.g. whenever you want to send data over the network or write it to a file) you need to encode it as a sequence of bytes. This chapter covers a variety of encodings for doing this.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The translation from the in-memory representation to a byte sequence is called `}<em parentName="p">{`encoding`}</em>{` (also known as `}<em parentName="p">{`serialization`}</em>{` or `}<em parentName="p">{`marshalling`}</em>{`), and the reverse is called `}<em parentName="p">{`decoding`}</em>{` (`}<em parentName="p">{`parsing`}</em>{`, `}<em parentName="p">{`deserialization`}</em>{`, `}<em parentName="p">{`unmarshalling`}</em>{`).`}</p>
    </blockquote>
    <p>{`Note: `}<em parentName="p">{`Serialization`}</em>{` is perhaps a more common term than `}<em parentName="p">{`encoding`}</em>{` but it is also used in the context of transactions with a completely different meaning.`}</p>
    <p>{`Types of encoding:`}</p>
    <ul>
      <li parentName="ul">{`Language-specific formats`}</li>
      <li parentName="ul">{`JSON and XML`}</li>
      <li parentName="ul">{`Binary encoding`}</li>
      <li parentName="ul">{`Thrift and Protocol Buffers`}</li>
      <li parentName="ul">{`Avro`}</li>
    </ul>
    <h4><HashLink id="language-formats" to="/data-intensive-apps#language-formats" mdxType="HashLink">{`Language-specific formats`}</HashLink></h4>
    <p>{`Generally a bad idea to use due to language specificity, security issues, data versioning/backward compatibility complexity and efficiency problems.`}</p>
    <h4><HashLink id="formats" to="/data-intensive-apps#formats" mdxType="HashLink">{`JSON, XML and binary variants`}</HashLink></h4>
    <p>{`There is a lot of ambiguity around the encoding of numbers.`}</p>
    <p>{`In XML and CSV, unless you refer to an external schema you cannot distinguish between a number and a string that happens to consist of digits.`}</p>
    <p>{`JSON distinguishes strings and numbers but it doesn’t distinguish integers and floating-point numbers. Also it doesn’t specify any precision.`}</p>
    <p>{`This is a problem when dealing with large numbers. For example, integers greater than 253 cannot be exactly represented in an IEEE 754 double-precision floating-point number, so such numbers become inaccurate when parsed in a language that uses floating-point numbers (e.g. JavaScript).`}</p>
    <blockquote>
      <p parentName="blockquote">{`An example of numbers larger than 253 occurs on Twitter, which uses a 64-bit number to identify each tweet. The JSON returned by Twitter’s API includes tweet IDs twice, once as a JSON number and once as a decimal string, to work around the fact that the numbers are not correctly parsed by JavaScript applications`}</p>
    </blockquote>
    <p>{`There's optional schema support for both XML and JSON. These schema languages are quite powerful but quite complicated to learn and implement. Use of XML schemas is much more widespread than JSON schemas. Since the correct interpretation of data (e.g. numbers and binary strings) depends on information in the schema, applications that don’t use XML/JSON schemas need to potentially hardcode the appropriate encoding/decoding logic instead.`}</p>
    <p>{`JSON and XML have good support for Unicode character strings (i.e., human-readable text), but they don’t support binary strings (sequences of bytes without a character encoding). Folks get around this limitation by encoding the binary data as text using Base64. A schema is then used to indicate that the value should be interpreted as Base64-encoded. This works, but it’s somewhat of a hack and it increases the data size by 33%.`}</p>
    <p>{`Despite these flaws, JSON and XML are good enough for many purposes. It’s likely that they will remain popular, especially as data interchange formats (i.e. for sending data from one organization to another). In these situations, as long as people agree on what the format is, it often doesn’t matter how pretty or efficient the format is.`}</p>
    <h4><HashLink id="buffers" to="/data-intensive-apps#buffers" mdxType="HashLink">{`Thrift and Protocol Buffers`}</HashLink></h4>
    <p>{`These binary schema–driven formats allow compact, efficient encoding with clearly defined forward and backward compatibility semantics. The schemas can be useful for documentation and code generation in statically typed languages. However, these formats have the downside that data needs to be decoded before it is human-readable.`}</p>
    <blockquote>
      <p parentName="blockquote">{`What about backward compatibility? As long as each field has a unique tag number, new code can always read old data, because the tag numbers still have the same meaning. The only detail is that if you add a new field, you cannot make it required. If you were to add a field and make it required, that check would fail if new code read data written by old code, because the old code will not have written the new field that you added. Therefore, to maintain backward compatibility, every field you add after the initial deployment of the schema must be optional or have a default value.`}</p>
    </blockquote>
    <p>{`A curious detail of Protocol Buffers is that it does not have a list or array datatype. Instead, it has a `}<inlineCode parentName="p">{`repeated`}</inlineCode>{` marker for fields (which is a third option alongside `}<inlineCode parentName="p">{`required`}</inlineCode>{` and `}<inlineCode parentName="p">{`optional`}</inlineCode>{`). A `}<inlineCode parentName="p">{`repeated`}</inlineCode>{` field indicates that the same field tag simply appears multiple times in the record. This has the nice effect that it’s okay to change an `}<inlineCode parentName="p">{`optional`}</inlineCode>{` (single-valued) field into a `}<inlineCode parentName="p">{`repeated`}</inlineCode>{` (multi-valued) field. New code reading old data sees a list with zero or one elements (depending on whether the field was present). Old code reading new data sees only the last element of the list.`}</p>
    <h3><HashLink id="data-flow" to="/data-intensive-apps#data-flow" mdxType="HashLink">{`Data flow`}</HashLink></h3>
    <p>{`Some of the most common ways how data flows between processes:`}</p>
    <ul>
      <li parentName="ul">{`databases`}</li>
      <li parentName="ul">{`service calls`}</li>
      <li parentName="ul">{`asynchronous message passing`}</li>
    </ul>
    <h4><HashLink id="databases" to="/data-intensive-apps#databases" mdxType="HashLink">{`Databases`}</HashLink></h4>
    <p>{`The process writing to the database encodes the data and the process reading from the database decodes it.`}</p>
    <h4><HashLink id="service-calls" to="/data-intensive-apps#service-calls" mdxType="HashLink">{`Service calls`}</HashLink></h4>
    <p>{`RPC and REST APIs, where the client encodes a request, the server decodes the request and encodes a response, and the client finally decodes the response.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The API of a SOAP web service is described using an XML-based language called the Web Services Description Language, or WSDL. WSDL enables code generation so that a client can access a remote service using local classes and method calls (which are encoded to XML messages and decoded again by the framework). This is useful in statically typed programming languages, but less so in dynamically typed ones (see `}<a parentName="p" {...{
          "href": "https://learning.oreilly.com/library/view/designing-data-intensive-applications/9781491903063/ch04.html#sec_encoding_code_generation"
        }}>{`“Code generation and dynamically typed languages”`}</a>{`).`}</p>
      <p parentName="blockquote">{`As WSDL is not designed to be human-readable, and as SOAP messages are often too complex to construct manually, users of SOAP rely heavily on tool support, code generation, and IDEs [`}<a parentName="p" {...{
          "href": "https://learning.oreilly.com/library/view/designing-data-intensive-applications/9781491903063/ch04.html#Lacey2006ul"
        }}>{`38`}</a>{`]. For users of programming languages that are not supported by SOAP vendors, integration with SOAP services is difficult.`}</p>
    </blockquote>
    <p>{`Custom RPC protocols with a binary encoding format can achieve better performance than something generic like JSON over a REST service call. However, a RESTful API has other significant advantages:`}</p>
    <ul>
      <li parentName="ul">{`it is good for experimentation and debugging - you can simply make requests to it using a web browser or the command-line tool `}<inlineCode parentName="li">{`curl`}</inlineCode>{`, without any code generation or software installation`}</li>
      <li parentName="ul">{`it is supported by all mainstream programming languages and platforms, and there is a vast ecosystem of tools available - servers, caches, load balancers, proxies, firewalls, monitoring, debugging tools, testing tools, etc.`}</li>
    </ul>
    <p>{`RPC frameworks are often used for calls between services owned by the same organization, typically within the same datacenter.`}</p>
    <h3><HashLink id="messaging" to="/data-intensive-apps#messaging" mdxType="HashLink">{`Message passing`}</HashLink></h3>
    <p>{`Nodes communicate by sending each other messages that are encoded by the sender and decoded by the recipient.`}</p>
    <p>{`Using a message broker has several advantages over direct RPC:`}</p>
    <ul>
      <li parentName="ul">{`It can act as a buffer if the recipient is unavailable or overloaded which improves system reliability.`}</li>
      <li parentName="ul">{`It can automatically redeliver messages to a process that has crashed which prevents messages from being lost.`}</li>
      <li parentName="ul">{`It avoids the sender needing to know the IP address and port number of the recipient. - Particularly useful in a cloud deployment where virtual machines often come and go.`}</li>
      <li parentName="ul">{`It allows one message to be sent to several recipients.`}</li>
      <li parentName="ul">{`It logically decouples the sender from the recipient. - The sender just publishes messages and doesn’t care who consumes them.`}</li>
    </ul>
    <p>{`However, message-passing communication is usually one-way. A sender normally doesn’t expect to receive a reply to its messages. It is possible for a process to send a response but it would usually be done via a separate channel.`}</p>
    <h2><HashLink id="part-2" to="/data-intensive-apps#part-2" mdxType="HashLink">{`Part 2: Distributed Data`}</HashLink></h2>
    <p>{`There are several reasons why you might want to replicate data:`}</p>
    <ul>
      <li parentName="ul">{`keep data geographically close to your users`}
        <ul parentName="li">
          <li parentName="ul">{`reduces access latency`}</li>
        </ul>
      </li>
      <li parentName="ul">{`allow the system to continue working even if some of its parts have failed`}
        <ul parentName="li">
          <li parentName="ul">{`increases availability / fault tolerance`}</li>
        </ul>
      </li>
      <li parentName="ul">{`scale out the number of machines that can serve read queries`}
        <ul parentName="li">
          <li parentName="ul">{`increases read throughput / scalability`}</li>
        </ul>
      </li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`If your data is distributed across multiple nodes, you need to be aware of the constraints and trade-offs that occur in such a distributed system—the database cannot magically hide these from you.`}</p>
    </blockquote>
    <p>{`2 common ways data is distributed across multiple nodes:`}</p>
    <ul>
      <li parentName="ul">{`Replication - Keeping a copy of the same data on several different nodes, potentially in different locations. - Replication provides redundancy: if some nodes are unavailable, the data can still be served from the remaining nodes. - Replication can also help improve performance.`}</li>
      <li parentName="ul">{`Partitioning - Splitting a big database into smaller subsets called `}<em parentName="li">{`partitions`}</em>{` so that different partitions can be assigned to different nodes (also known as `}<em parentName="li">{`sharding`}</em>{`).`}</li>
    </ul>
    <p>{`Node failures, unreliable networks, and trade-offs around replica consistency, durability, availability, and latency are fundamental problems in distributed systems.`}</p>
    <h2><HashLink id="chapter-5" to="/data-intensive-apps#chapter-5" mdxType="HashLink">{`Chapter 5: Replication`}</HashLink></h2>
    <p>{`All of the difficulty in replication lies in handling `}<em parentName="p">{`changes`}</em>{` to replicated data. Three popular algorithms for replicating changes between nodes are `}<em parentName="p">{`single-leader`}</em>{`, `}<em parentName="p">{`multi-leader`}</em>{`, and `}<em parentName="p">{`leaderless`}</em>{` replication.`}</p>
    <h3><HashLink id="single-leader" to="/data-intensive-apps#single-leader" mdxType="HashLink">{`Single-leader replication`}</HashLink></h3>
    <p>{`Clients send all writes to a single node (the leader), which sends a stream of data change events to the other replicas (followers). Reads can be performed on any replica, but reads from followers might be stale.`}</p>
    <div style={{
      "display": "flex",
      "justifyContent": "center"
    }}>
  <img src={singleLeaderImg} alt="Diagram explaining bug scenario without single leader replication" width="100%" />
    </div>
    <p>{`This approach is popular because it's relatively easy to understand and there is no conflict resolution to worry about.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The advantage of synchronous replication is that the follower is guaranteed to have an up-to-date copy of the data that is consistent with the leader. If the leader suddenly fails, we can be sure that the data is still available on the follower. The disadvantage is that if the synchronous follower doesn’t respond (because it has crashed, or there is a network fault, or for any other reason), the write cannot be processed. The leader must block all writes and wait until the synchronous replica is available again.`}</p>
    </blockquote>
    <p>{`If you enable synchronous replication on a database it usually means that `}<em parentName="p">{`one`}</em>{` of the followers is synchronous and the others are asynchronous. If the synchronous follower becomes unavailable or slow, one of the asynchronous followers is made synchronous. This guarantees that you have an up-to-date copy of the data on at least two nodes (the leader and one synchronous follower). This configuration is sometimes also called `}<em parentName="p">{`semi-synchronous`}</em>{`.`}</p>
    <p>{`The figure below represents leader-based replication with one synchronous and one asynchronous follower.`}</p>
    <div style={{
      "display": "flex",
      "justifyContent": "center"
    }}>
  <img src={asyncFollowerImg} alt="Diagram explaining an approach for one synchronous and one asynchronous follower" width="100%" />
    </div>
    <h3><HashLink id="multi-leader" to="/data-intensive-apps#multi-leader" mdxType="HashLink">{`Multi-leader replication`}</HashLink></h3>
    <p>{`Clients send each write to one of several leader nodes, any of which can accept writes. The leaders send streams of data change events to each other and to any follower nodes.`}</p>
    <p>{`This approach can be more robust in the presence of faulty nodes, network interruptions and latency spikes. This comes at the cost of being harder to reason about as well as the cost of very weak consistency guarantees.`}</p>
    <p>{`The figure below outlines an approach for multi-leader replication across multiple data centers.`}</p>
    <div style={{
      "display": "flex",
      "justifyContent": "center"
    }}>
  <img src={multiLeaderImg} alt="Multi leader replication diagram" width="100%" />
    </div>
    <h3><HashLink id="leaderless" to="/data-intensive-apps#leaderless" mdxType="HashLink">{`Leaderless replication`}</HashLink></h3>
    <p>{`Clients send each write to several nodes, and read from several nodes in parallel in order to detect and correct nodes with stale data.`}</p>
    <h3><HashLink id="consistency" to="/data-intensive-apps#consistency" mdxType="HashLink">{`Eventual consistency models`}</HashLink></h3>
    <p>{`There are a few consistency models which are helpful for deciding how an application should behave under replication lag:`}</p>
    <h4><HashLink id="read-after" to="/data-intensive-apps#read-after" mdxType="HashLink">{`Read-after-write consistency`}</HashLink></h4>
    <p>{`Users should always see data that they submitted themselves. This consistency model makes no promises about other users' updates being visible in real time.`}</p>
    <p><em parentName="p">{`Cross-device`}</em>{` read-after-write consistency ensures that if the user enters some information on one device and then views it on another device, they should see the information they just entered.`}</p>
    <h4><HashLink id="monotonic" to="/data-intensive-apps#monotonic" mdxType="HashLink">{`Monotonic reads`}</HashLink></h4>
    <p>{`After users have seen the data at one point in time, they shouldn’t later see the data from some earlier point in time.`}</p>
    <p>{`In the figure below, a user first reads from a fresh replica, then from a stale replica. Time appears to go backward. To prevent this anomaly, we need monotonic reads.`}</p>
    <div style={{
      "display": "flex",
      "justifyContent": "center"
    }}>
  <img src={monotonicReadsImg} alt="Diagram explaining bug scenario without monotonic reads" width="100%" />
    </div>
    <h4><HashLink id="consistent" to="/data-intensive-apps#consistent" mdxType="HashLink">{`Consistent prefix reads`}</HashLink></h4>
    <p>{`Users should see the data in a state that makes causal sense: for example, seeing a question and its reply in the correct order.`}</p>
    <p>{`If some partitions are replicated slower than others, an observer may see the answer before they see the question. The figure below demonstrates this scenario.`}</p>
    <div style={{
      "display": "flex",
      "justifyContent": "center"
    }}>
  <img src={consistentPrefixImg} alt="Diagram explaining bug scenario without consistent prefix reads" width="100%" />
    </div>
    <h2><HashLink id="chapter-6" to="/data-intensive-apps#chapter-6" mdxType="HashLink">{`Chapter 6: Partitioning`}</HashLink></h2>
    <p>{`Partitioning is necessary when you have so much data that storing and processing it on a single machine is no longer feasible.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The goal of partitioning is to spread the data and query load evenly across multiple machines, avoiding hot spots (nodes with disproportionately high load). This requires choosing a partitioning scheme that is appropriate to your data, and rebalancing the partitions when nodes are added to or removed from the cluster.`}</p>
    </blockquote>
    <h3><HashLink id="partition" to="/data-intensive-apps#partition" mdxType="HashLink">{`Main approaches to partitioning:`}</HashLink></h3>
    <ul>
      <li parentName="ul">{`Key range partitioning`}</li>
      <li parentName="ul">{`Hash partitioning`}</li>
    </ul>
    <h4><HashLink id="key-range" to="/data-intensive-apps#key-range" mdxType="HashLink">{`Key range partitioning`}</HashLink></h4>
    <p>{`Keys are sorted and a partition owns all the keys from some minimum up to some maximum. Sorting has the advantage that efficient range queries are possible but there is a risk of hot spots if the application often accesses keys that are close together in the sorted order.`}</p>
    <p>{`In this approach, partitions are typically rebalanced dynamically by splitting the range into two subranges when a partition gets too big.`}</p>
    <h4><HashLink id="hash" to="/data-intensive-apps#hash" mdxType="HashLink">{`Hash partitioning`}</HashLink></h4>
    <p>{`A hash function is applied to each key and a partition owns a range of hashes. This method destroys the ordering of keys which makes range queries inefficient but may distribute load more evenly.`}</p>
    <p>{`When partitioning by hash it's common to create a fixed number of partitions in advance, to assign several partitions to each node, and to move entire partitions from one node to another when nodes are added or removed. Dynamic partitioning can also be used.`}</p>
    <h3><HashLink id="secondary-index" to="/data-intensive-apps#secondary-index" mdxType="HashLink">{`Partitioning and secondary indexes`}</HashLink></h3>
    <p>{`A secondary index also needs to be partitioned, and there are two methods:`}</p>
    <ol>
      <li parentName="ol">{`Document-partitioned indexes`}</li>
      <li parentName="ol">{`Term-partitioned indexes`}</li>
    </ol>
    <h3><HashLink id="document-partition" to="/data-intensive-apps#document-partition" mdxType="HashLink">{`Document-partitioned indexes (local indexes)`}</HashLink></h3>
    <p>{`The secondary indexes are stored in the same partition as the primary key and value. This means that only a single partition needs to be updated on write, but a read of the secondary index requires a scatter/gather across all partitions.`}</p>
    <h3><HashLink id="term-partition" to="/data-intensive-apps#term-partition" mdxType="HashLink">{`Term-partitioned indexes (global indexes)`}</HashLink></h3>
    <p>{`The secondary indexes are partitioned separately, using the indexed values. An entry in the secondary index may include records from all partitions of the primary key. When a document is written, several partitions of the secondary index need to be updated; however, a read can be served from a single partition.`}</p>
    <h3><HashLink id="routing" to="/data-intensive-apps#routing" mdxType="HashLink">{`Routing`}</HashLink></h3>
    <p>{`Techniques for routing queries to the appropriate partition range from simple partition-aware load balancing to sophisticated parallel query execution engines.`}</p>
    <p>{`By design, every partition operates mostly independently which allows a partitioned database to scale to multiple machines.`}</p>
    <p>{`However, operations that need to write to several partitions can be difficult to reason about. For example, what happens if the write to one partition succeeds, but another fails?`}</p>
    <h2><HashLink id="chapter-7" to="/data-intensive-apps#chapter-7" mdxType="HashLink">{`Chapter 7: Transactions`}</HashLink></h2>
    <blockquote>
      <p parentName="blockquote">{`A transaction is a way for an application to group several reads and writes together into a logical unit.`}</p>
    </blockquote>
    <p>{`An application with very simple access patterns can probably manage without transactions. For example, reading and writing only a single record.`}</p>
    <p>{`For more complex apps, the following error scenarios could cause data to become inconsistent.`}</p>
    <ul>
      <li parentName="ul">{`processes crashing`}</li>
      <li parentName="ul">{`network interruptions`}</li>
      <li parentName="ul">{`power outages`}</li>
      <li parentName="ul">{`disk full`}</li>
      <li parentName="ul">{`unexpected concurrency`}</li>
    </ul>
    <p>{`Transactions eliminate the need to think about these potential error cases.`}</p>
    <h3><HashLink id="concurrency" to="/data-intensive-apps#concurrency" mdxType="HashLink">{`Concurrency control`}</HashLink></h3>
    <p>{`Widely used isolation levels:`}</p>
    <ul>
      <li parentName="ul">{`read committed`}</li>
      <li parentName="ul">{`snapshot isolation (sometimes called `}<em parentName="li">{`repeatable read`}</em>{`)`}</li>
      <li parentName="ul">{`serializable`}</li>
    </ul>
    <p>{`These isolation levels can be characterized by discussing various examples of race conditions:`}</p>
    <ul>
      <li parentName="ul">{`dirty reads`}</li>
      <li parentName="ul">{`dirty writes`}</li>
      <li parentName="ul">{`read skew`}</li>
      <li parentName="ul">{`lost updates`}</li>
      <li parentName="ul">{`write skew`}</li>
      <li parentName="ul">{`phantom reads`}</li>
    </ul>
    <h4><HashLink id="dirty-reads" to="/data-intensive-apps#dirty-reads" mdxType="HashLink">{`Dirty reads`}</HashLink></h4>
    <p>{`One client reads another client’s writes before they have been committed. The read committed isolation level and stronger levels prevent dirty reads.`}</p>
    <h4><HashLink id="dirty-writes" to="/data-intensive-apps#dirty-writes" mdxType="HashLink">{`Dirty writes`}</HashLink></h4>
    <p>{`One client overwrites data that another client has written, but not yet committed. Almost all transaction implementations prevent dirty writes.`}</p>
    <h4><HashLink id="read-skew" to="/data-intensive-apps#read-skew" mdxType="HashLink">{`Read skew`}</HashLink></h4>
    <p>{`A client sees different parts of the database at different points in time. Some cases of read skew are also known as `}<em parentName="p">{`nonrepeatable reads`}</em>{`. This issue is most commonly prevented with snapshot isolation, which allows a transaction to read from a consistent snapshot corresponding to one particular point in time. It is usually implemented with `}<em parentName="p">{`multi-version concurrency control`}</em>{` (MVCC).`}</p>
    <h4><HashLink id="lost-updates" to="/data-intensive-apps#lost-updates" mdxType="HashLink">{`Lost updates`}</HashLink></h4>
    <p>{`Two clients concurrently perform a read-modify-write cycle. One overwrites the other’s write without incorporating its changes, so data is lost. Some implementations of snapshot isolation prevent this anomaly automatically, while others require a manual lock (`}<inlineCode parentName="p">{`SELECT FOR UPDATE`}</inlineCode>{`).`}</p>
    <h4><HashLink id="write-skew" to="/data-intensive-apps#write-skew" mdxType="HashLink">{`Write skew`}</HashLink></h4>
    <p>{`A transaction reads something, makes a decision based on the value it saw, and writes the decision to the database. However, by the time the write is made, the premise of the decision is no longer true. Only serializable isolation prevents this anomaly.`}</p>
    <h4><HashLink id="phantom-reads" to="/data-intensive-apps#phantom-reads" mdxType="HashLink">{`Phantom reads`}</HashLink></h4>
    <p>{`A transaction reads objects that match some search condition. Another client makes a write that affects the results of that search. Snapshot isolation prevents straightforward phantom reads, but phantoms in the context of write skew require special treatment, such as index-range locks.`}</p>
    <h3><HashLink id="serialize" to="/data-intensive-apps#serialize" mdxType="HashLink">{`Serializable transactions`}</HashLink></h3>
    <p>{`Weak isolation levels protect against some of those anomalies but leave you, the application developer, to handle others manually (e.g., using explicit locking). Only serializable isolation protects against all of these issues.`}</p>
    <p>{`Approaches to implementing serializable transactions:`}</p>
    <ul>
      <li parentName="ul">{`Literally executing transactions in a serial order`}</li>
      <li parentName="ul">{`Two-phase locking`}</li>
      <li parentName="ul">{`Serializable snapshot isolation (SSI)`}</li>
    </ul>
    <h4><HashLink id="serial" to="/data-intensive-apps#serial" mdxType="HashLink">{`Literally executing transactions in a serial order`}</HashLink></h4>
    <p>{`If you can make each transaction very fast to execute, and the transaction throughput is low enough to process on a single CPU core, this is a simple and effective option.`}</p>
    <h4><HashLink id="phase-locking" to="/data-intensive-apps#phase-locking" mdxType="HashLink">{`Two-phase locking`}</HashLink></h4>
    <p>{`For decades this has been the standard way of implementing serializability, but many applications avoid using it because of its performance characteristics.`}</p>
    <h4><HashLink id="snapshot" to="/data-intensive-apps#snapshot" mdxType="HashLink">{`Serializable snapshot isolation (SSI)`}</HashLink></h4>
    <p>{`A fairly new algorithm that avoids most of the downsides of the previous approaches. It uses an optimistic approach, allowing transactions to proceed without blocking. When a transaction wants to commit, it is checked, and it is aborted if the execution was not serializable.`}</p>
    <h2><HashLink id="chapter-8" to="/data-intensive-apps#chapter-8" mdxType="HashLink">{`Chapter 8: The Trouble with Distributed Systems`}</HashLink></h2>
    <p>{`If you can simply keep things on a single machine, it is generally worth doing so.`}</p>
    <p>{`Scalability is not the only reason for wanting to use a distributed system. Fault tolerance and low latency (by placing data geographically close to users) are equally important goals, and those things cannot be achieved with a single node.`}</p>
    <h3><HashLink id="partial-fail" to="/data-intensive-apps#partial-fail" mdxType="HashLink">{`Partial failures`}</HashLink></h3>
    <p>{`The defining characteristic of distributed systems is the fact that they can have `}<em parentName="p">{`partial failures`}</em>{`.`}</p>
    <p>{`Some problems that can occur in distributed systems:`}</p>
    <ul>
      <li parentName="ul">{`Sending packets over the network may be lost or arbitrarily delayed. - Likewise, the reply may be lost or delayed. - If you don’t get a reply you have no idea whether the message got through.`}</li>
      <li parentName="ul">{`A node’s clock may be significantly out of sync with other nodes. - It may suddenly jump forward or back in time. - Relying on it is dangerous.`}</li>
      <li parentName="ul">{`A process may pause for a substantial amount of time at any point in its execution. - For example, due to a stop-the-world garbage collector. - Paused processes may also be declared dead by other nodes and then come back to life again without realizing that it was paused.`}</li>
    </ul>
    <p>{`Whenever software tries to do anything involving other nodes there is the possibility that it may:`}</p>
    <ul>
      <li parentName="ul">{`occasionally fail`}</li>
      <li parentName="ul">{`randomly slow down`}</li>
      <li parentName="ul">{`not respond at all (and eventually time out)`}</li>
    </ul>
    <h3><HashLink id="fault-tolerance" to="/data-intensive-apps#fault-tolerance" mdxType="HashLink">{`Fault tolerance`}</HashLink></h3>
    <p>{`In distributed systems `}<em parentName="p">{`fault tolerance`}</em>{` allows the system as a whole to continue functioning even when some of its constituent parts are broken.`}</p>
    <p>{`The first step is to `}<em parentName="p">{`detect`}</em>{` the faults which isn't easy.`}</p>
    <p>{`Most systems rely on timeouts to determine whether a remote node is still available. Timeouts can’t distinguish between network and node failures. Variable network delay sometimes causes a node to be falsely suspected of crashing.`}</p>
    <p>{`Once a fault is detected, making a system tolerate it is not easy either since there is no shared state between the machines. Major decisions cannot be safely made by a single node. Instead we require protocols that enlist help from other nodes and try to get a quorum to agree via message passing over a network.`}</p>
    <p>{`Unreliability of networks, clocks, and processes is avoidable. It's possible to give hard real-time response guarantees and bounded delays in networks. However, doing so is very expensive and results in lower utilization of hardware resources. Most non-safety-critical systems choose cheap and unreliable over expensive and reliable.`}</p>
    <h2><HashLink id="chapter-9" to="/data-intensive-apps#chapter-9" mdxType="HashLink">{`Chapter 9: Consistency and Consensus`}</HashLink></h2>
    <h3><HashLink id="consistency-model" to="/data-intensive-apps#consistency-model" mdxType="HashLink">{`Consistency models`}</HashLink></h3>
    <h4><HashLink id="linearizability" to="/data-intensive-apps#linearizability" mdxType="HashLink">{`Linearizability`}</HashLink></h4>
    <p><em parentName="p">{`Linearizability`}</em>{` is a popular consistency model. Its goal is to make replicated data appear as though there were only a single copy, and to make all operations act on it atomically.`}</p>
    <p>{`Linearizability makes a database behave like a variable in a single-threaded program. It's appealing because it's easy to understand but it has the downside of being slow, especially in environments with large network delays.`}</p>
    <h4><HashLink id="causality" to="/data-intensive-apps#causality" mdxType="HashLink">{`Causality`}</HashLink></h4>
    <p><em parentName="p">{`Causality`}</em>{` imposes an ordering on events in a system (what happened before what, based on cause and effect).`}</p>
    <p>{`Unlike linearizability which puts all operations in a single, ordered timeline, causality provides us with a weaker consistency model. Some things can be concurrent so the version history is like a timeline with branching and merging.`}</p>
    <p>{`Causal consistency does not have the coordination overhead of linearizability and is much less sensitive to network problems.`}</p>
    <p>{`Causality is not a silver bullet. There are limitations. Not all things can be implemented this way.`}</p>
    <p>{`Consider an example of ensuring that a username is unique and rejecting concurrent registrations for the same username. If one node is going to accept a registration, it needs to somehow know that another node isn’t concurrently in the process of registering the same name.`}</p>
    <p>{`We can solve this problem with `}<em parentName="p">{`consensus`}</em>{`.`}</p>
    <h3><HashLink id="consensus" to="/data-intensive-apps#consensus" mdxType="HashLink">{`Consensus`}</HashLink></h3>
    <p>{`Achieving consensus means deciding something in such a way that all nodes agree on what was decided and the decision is irrevocable.`}</p>
    <p>{`Not every system necessarily requires consensus. For example, leaderless and multi-leader replication systems typically do not use global consensus.`}</p>
    <p>{`Tools like ZooKeeper play an important role in providing an “outsourced” consensus, failure detection, and membership service that applications can use. It’s not easy to use, but it is much better than trying to develop your own algorithms that can withstand all the potential problems. If you find yourself wanting to do one of those things that is reducible to consensus, and you want it to be fault-tolerant, then it is advisable to use something like ZooKeeper.`}</p>
    <p>{`A wide range of problems are actually reducible to consensus and are equivalent to each other (if you have a solution for one of them, you can easily transform it into a solution for one of the others). Such problems include:`}</p>
    <h4><HashLink id="registers" to="/data-intensive-apps#registers" mdxType="HashLink">{`Linearizable compare-and-set registers`}</HashLink></h4>
    <p>{`The register needs to atomically `}<em parentName="p">{`decide`}</em>{` whether to set its value, based on whether its current value equals the parameter given in the operation.`}</p>
    <h4><HashLink id="commit" to="/data-intensive-apps#commit" mdxType="HashLink">{`Atomic transaction commit`}</HashLink></h4>
    <p>{`A database must `}<em parentName="p">{`decide`}</em>{` whether to commit or abort a distributed transaction.`}</p>
    <h4><HashLink id="broadcast" to="/data-intensive-apps#broadcast" mdxType="HashLink">{`Total order broadcast`}</HashLink></h4>
    <p>{`The messaging system must `}<em parentName="p">{`decide`}</em>{` on the order in which to deliver messages.`}</p>
    <h4><HashLink id="locks" to="/data-intensive-apps#locks" mdxType="HashLink">{`Locks and leases`}</HashLink></h4>
    <p>{`When several clients are racing to grab a lock or lease, the lock `}<em parentName="p">{`decides`}</em>{` which one successfully acquired it.`}</p>
    <h4><HashLink id="membership" to="/data-intensive-apps#membership" mdxType="HashLink">{`Membership/coordination service`}</HashLink></h4>
    <p>{`Given a failure detector (e.g., timeouts), the system must `}<em parentName="p">{`decide`}</em>{` which nodes are alive, and which should be considered dead because their sessions timed out.`}</p>
    <h4><HashLink id="uniqueness" to="/data-intensive-apps#uniqueness" mdxType="HashLink">{`Uniqueness constraint`}</HashLink></h4>
    <p>{`When several transactions concurrently try to create conflicting records with the same key, the constraint must `}<em parentName="p">{`decide`}</em>{` which one to allow and which should fail with a constraint violation.`}</p>
    <h3><HashLink id="single-leader-db" to="/data-intensive-apps#single-leader-db" mdxType="HashLink">{`Single-leader databases`}</HashLink></h3>
    <p>{`All of these are straightforward if you only have a single node or if you are willing to assign the decision-making capability to a single node.`}</p>
    <p>{`This is what happens in a single-leader database. All the power to make decisions is vested in the leader. This is why single-leader databases are able to provide linearizable operations, uniqueness constraints, a totally ordered replication log, and more.`}</p>
    <p>{`However, if that single leader fails, or if a network interruption makes the leader unreachable such a system becomes unable to make any progress. There are three ways of handling that situation:`}</p>
    <ol>
      <li parentName="ol">{`Wait for the leader to recover and accept that the system will be blocked in the meantime.`}
        <ul parentName="li">
          <li parentName="ul">{`This approach does not fully solve consensus because it does not satisfy the termination property (if the leader does not recover, the system can be blocked forever).`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Manually fail over by getting humans to choose a new leader node and reconfigure the system to use it.`}
        <ul parentName="li">
          <li parentName="ul">{`Many relational databases take this approach.`}</li>
          <li parentName="ul">{`The speed of failover is limited by the speed at which humans can act which is generally slower than computers.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Use an algorithm to automatically choose a new leader.`}
        <ul parentName="li">
          <li parentName="ul">{`This approach requires a consensus algorithm and it should handle adverse network conditions.`}</li>
        </ul>
      </li>
    </ol>
    <p>{`Although a single-leader database can provide linearizability without executing a consensus algorithm on every write, it still requires consensus to maintain its leadership and for leadership changes. In some sense, having a leader only “kicks the can down the road”. Consensus is still required, only in a different place and less frequently.`}</p>
    <h2><HashLink id="part-3" to="/data-intensive-apps#part-3" mdxType="HashLink">{`Part 3: Derived Data`}</HashLink></h2>
    <p>{`Up until this part of the book there was an assumption that there was only one database in the application.`}</p>
    <p>{`In practice, applications commonly use a combination of several different data stores, caches, analytics systems, etc. These applications implement mechanisms for moving data from one store to another.`}</p>
    <p>{`Systems that store and process data can be grouped into two generic categories:`}</p>
    <ul>
      <li parentName="ul">{`Systems of record`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`A system of record, also known as `}<em parentName="p">{`source of truth`}</em>{`, holds the authoritative version of your data. When new data comes in, e.g., as user input, it is first written here. Each fact is represented exactly once (the representation is typically `}<em parentName="p">{`normalized`}</em>{`). If there is any discrepancy between another system and the system of record, then the value in the system of record is (by definition) the correct one.`}</p>
        </blockquote>
      </li>
      <li parentName="ul">{`Derived data systems`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`Data in a derived system is the result of taking some existing data from another system and transforming or processing it in some way. If you lose derived data, you can recreate it from the original source. A classic example is a cache: data can be served from the cache if present, but if the cache doesn’t contain what you need, you can fall back to the underlying database. Denormalized values, indexes, and materialized views also fall into this category. In recommendation systems, predictive summary data is often derived from usage logs.`}</p>
        </blockquote>
      </li>
    </ul>
    <p>{`Part 3 examines the issues around integrating multiple different data systems into one coherent application architecture. These data systems potentially have different data models and are optimized for different access patterns.`}</p>
    <p>{`The `}<em parentName="p">{`data integration`}</em>{` problem that arises can be solved by using batch processing and event streams to let data changes flow between different systems.`}</p>
    <p>{`Certain systems are designated as systems of record and other data is derived from them through transformations. We can maintain indexes, materialized views, machine learning models, statistical summaries, etc.`}</p>
    <p>{`A problem in one area is prevented from spreading to unrelated parts of the system by making these derivations and transformations asynchronous and loosely coupled. This increases the robustness and fault-tolerance of the system as a whole.`}</p>
    <h2><HashLink id="chapter-10" to="/data-intensive-apps#chapter-10" mdxType="HashLink">{`Chapter 10: Batch Processing`}</HashLink></h2>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`A system cannot be successful if it is too strongly influenced by a single person. Once the initial design is complete and fairly robust, the real test begins as people with many different viewpoints undertake their own experiments.`}</em></p>
      <p parentName="blockquote">{`Donald Knuth`}</p>
    </blockquote>
    <p>{`Three different types of systems:`}</p>
    <ul>
      <li parentName="ul">{`Services (online systems)`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`A service waits for a request or instruction from a client to arrive. When one is received, the service tries to handle it as quickly as possible and sends a response back. Response time is usually the primary measure of performance of a service, and availability is often very important (if the client can’t reach the service, the user will probably get an error message).`}</p>
        </blockquote>
      </li>
      <li parentName="ul">{`Batch processing systems (offline systems)`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`A batch processing system takes a large amount of input data, runs a `}<em parentName="p">{`job`}</em>{` to process it, and produces some output data. Jobs often take a while (from a few minutes to several days), so there normally isn’t a user waiting for the job to finish. Instead, batch jobs are often scheduled to run periodically (for example, once a day). The primary performance measure of a batch job is usually `}<em parentName="p">{`throughput`}</em>{` (the time it takes to crunch through an input dataset of a certain size). We discuss batch processing in this chapter.`}</p>
        </blockquote>
      </li>
      <li parentName="ul">{`Stream processing systems (near-real-time systems)`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`Stream processing is somewhere between online and offline/batch processing (so it is sometimes called `}<em parentName="p">{`near-real-time`}</em>{` or `}<em parentName="p">{`nearline`}</em>{` processing). Like a batch processing system, a stream processor consumes inputs and produces outputs (rather than responding to requests). However, a stream job operates on events shortly after they happen, whereas a batch job operates on a fixed set of input data. This difference allows stream processing systems to have lower latency than the equivalent batch systems. As stream processing builds upon batch processing, we discuss it in `}<a parentName="p" {...{
              "href": "https://learning.oreilly.com/library/view/designing-data-intensive-applications/9781491903063/ch11.html#ch_stream"
            }}>{`Chapter 11`}</a>{`.`}</p>
        </blockquote>
      </li>
    </ul>
    <p>{`Even if various tasks have to be retried, a batch processing framework can guarantee that the final output of a job is the same as if no faults happened.`}</p>
    <p>{`The design philosophy of `}<inlineCode parentName="p">{`awk`}</inlineCode>{`, `}<inlineCode parentName="p">{`grep`}</inlineCode>{` and `}<inlineCode parentName="p">{`sort`}</inlineCode>{` Unix tools lead to MapReduce which led to more recent "dataflow engines".`}</p>
    <p>{`Some original design principles:`}</p>
    <ul>
      <li parentName="ul">{`inputs are immutable`}</li>
      <li parentName="ul">{`outputs are intended to become the input to another program`}</li>
      <li parentName="ul">{`complex problems are solved by composing small tools that "do one thing well"`}</li>
    </ul>
    <p>{`A batch processing job reminds me of a pure function.`}</p>
    <ul>
      <li parentName="ul">{`It reads some input data and produces some output data without modifying the input. - The output is derived from the the input`}</li>
      <li parentName="ul">{`There is a known, fixed size to the input data set - A job knows when the entire input is processed`}</li>
    </ul>
    <p>{`The next chapter covers when the input is unbounded.`}</p>
    <h3><HashLink id="why-batches" to="/data-intensive-apps#why-batches" mdxType="HashLink">{`Why batch processing?`}</HashLink></h3>
    <p>{`The two main problems that distributed batch processing frameworks need to solve are partitioning and fault tolerance.`}</p>
    <h4><HashLink id="partitioning" to="/data-intensive-apps#partitioning" mdxType="HashLink">{`Partitioning`}</HashLink></h4>
    <p>{`In MapReduce, mappers are partitioned according to input file blocks. The output of mappers is repartitioned, sorted, and merged into a configurable number of reducer partitions. The purpose of this process is to bring all the related data (e.g. all the records with the same key) together in the same place.`}</p>
    <p>{`More recent dataflow engines try to avoid sorting unless it is required but they otherwise take a similar approach to partitioning, in general.`}</p>
    <h4><HashLink id="batch-faults" to="/data-intensive-apps#batch-faults" mdxType="HashLink">{`Fault tolerance`}</HashLink></h4>
    <p>{`MapReduce frequently writes to disk which makes it easy to recover from an individual failed task without restarting the entire job but slows down execution in the failure-free case.`}</p>
    <p>{`Dataflow engines perform less materialization of intermediate state and keep more in memory. This means that they need to recompute more data if a node fails. There are ways to reduce the amount of data that needs to be recomputed.`}</p>
    <h3><HashLink id="batch-internals" to="/data-intensive-apps#batch-internals" mdxType="HashLink">{`Under the hood`}</HashLink></h3>
    <p>{`There are several join algorithms for MapReduce. Most of them are also internally used in MPP databases and dataflow engines.`}</p>
    <p>{`These joins provide a good illustration of how partitioned algorithms work:`}</p>
    <ul>
      <li parentName="ul">{`Sort-merge joins`}</li>
      <li parentName="ul">{`Broadcast hash joins`}</li>
      <li parentName="ul">{`Partitioned hash joins`}</li>
    </ul>
    <h2><HashLink id="chapter-11" to="/data-intensive-apps#chapter-11" mdxType="HashLink">{`Chapter 11: Stream Processing`}</HashLink></h2>
    <p>{`Stream processing is very much like the batch processing but done continuously on never-ending streams rather than on a fixed-size input.`}</p>
    <blockquote>
      <p parentName="blockquote">{`In reality, a lot of data is unbounded because it arrives gradually over time: your users produced data yesterday and today, and they will continue to produce more data tomorrow.`}</p>
    </blockquote>
    <p>{`Rather than using batch processing to divide the data into chunks (e.g. running a nightly ETL job) we can use stream processing to capture event data when it is first introduced. There is utility in this for things like analytics systems where the users may not be able to wait a day to work with the most up-to-date data.`}</p>
    <p>{`Common purposes for stream processing:`}</p>
    <ul>
      <li parentName="ul">{`searching for event patterns (complex event processing)`}</li>
      <li parentName="ul">{`computing windowed aggregations (stream analytics)`}</li>
      <li parentName="ul">{`keeping derived data systems up to date (materialized views)`}</li>
    </ul>
    <p>{`Some stream processing difficulties reasoning about time:`}</p>
    <ul>
      <li parentName="ul">{`processing time vs event timestamps`}</li>
      <li parentName="ul">{`dealing with straggler events that arrive after you thought your window was complete`}</li>
    </ul>
    <h3><HashLink id="brokers" to="/data-intensive-apps#brokers" mdxType="HashLink">{`Message brokers`}</HashLink></h3>
    <p>{`Two types of message brokers:`}</p>
    <ul>
      <li parentName="ul">{`AMQP/JMS-style message broker`}</li>
      <li parentName="ul">{`Log-based message broker`}</li>
    </ul>
    <h4><HashLink id="jms" to="/data-intensive-apps#jms" mdxType="HashLink">{`AMQP/JMS-style message broker`}</HashLink></h4>
    <blockquote>
      <p parentName="blockquote">{`The broker assigns individual messages to consumers, and consumers acknowledge individual messages when they have been successfully processed. Messages are deleted from the broker once they have been acknowledged. This approach is appropriate as an asynchronous form of RPC (see also `}<a parentName="p" {...{
          "href": "https://learning.oreilly.com/library/view/designing-data-intensive-applications/9781491903063/ch04.html#sec_encoding_dataflow_msg"
        }}>{`“Message-Passing Dataflow”`}</a>{`), for example in a task queue, where the exact order of message processing is not important and where there is no need to go back and read old messages again after they have been processed.`}</p>
    </blockquote>
    <h4><HashLink id="log-message" to="/data-intensive-apps#log-message" mdxType="HashLink">{`Log-based message broker`}</HashLink></h4>
    <blockquote>
      <p parentName="blockquote">{`The broker assigns all messages in a partition to the same consumer node, and always delivers messages in the same order. Parallelism is achieved through partitioning, and consumers track their progress by checkpointing the offset of the last message they have processed. The broker retains messages on disk, so it is possible to jump back and reread old messages if necessary.`}</p>
    </blockquote>
    <p>{`This approach is great for stream processing systems that consume input streams and generate derived state or derived output streams.`}</p>
    <h3><HashLink id="stream-source" to="/data-intensive-apps#stream-source" mdxType="HashLink">{`Stream data sources`}</HashLink></h3>
    <p>{`Common sources of streams:`}</p>
    <ul>
      <li parentName="ul">{`user activity events`}</li>
      <li parentName="ul">{`sensors providing periodic readings`}</li>
      <li parentName="ul">{`data feeds (e.g. market data in finance)`}</li>
    </ul>
    <p>{`It's useful to think of the writes to a database as a stream.`}</p>
    <p>{`We can capture the history of all changes made to a database either implicitly through change data capture or explicitly through event sourcing.`}</p>
    <p>{`You can keep derived data systems such as search indexes, caches, and analytics systems continually up to date by:`}</p>
    <ul>
      <li parentName="ul">{`consuming a log of database changes`}</li>
      <li parentName="ul">{`applying them to the derived system`}</li>
    </ul>
    <h3><HashLink id="stream-process" to="/data-intensive-apps#stream-process" mdxType="HashLink">{`Stream processing joins`}</HashLink></h3>
    <p>{`Three types of joins that may appear in stream processes:`}</p>
    <ul>
      <li parentName="ul">{`Stream-stream joins`}</li>
      <li parentName="ul">{`Stream-table joins`}</li>
      <li parentName="ul">{`Table-table joins`}</li>
    </ul>
    <h4><HashLink id="stream-stream" to="/data-intensive-apps#stream-stream" mdxType="HashLink">{`Stream-stream joins`}</HashLink></h4>
    <blockquote>
      <p parentName="blockquote">{`Both input streams consist of activity events, and the join operator searches for related events that occur within some window of time. For example, it may match two actions taken by the same user within 30 minutes of each other. The two join inputs may in fact be the same stream (a `}<em parentName="p">{`self-join`}</em>{`) if you want to find related events within that one stream.`}</p>
    </blockquote>
    <h4><HashLink id="stream-table" to="/data-intensive-apps#stream-table" mdxType="HashLink">{`Stream-table joins`}</HashLink></h4>
    <blockquote>
      <p parentName="blockquote">{`One input stream consists of activity events, while the other is a database changelog. The changelog keeps a local copy of the database up to date. For each activity event, the join operator queries the database and outputs an enriched activity event.`}</p>
    </blockquote>
    <h4><HashLink id="table-table" to="/data-intensive-apps#table-table" mdxType="HashLink">{`Table-table joins`}</HashLink></h4>
    <blockquote>
      <p parentName="blockquote">{`Both input streams are database changelogs. In this case, every change on one side is joined with the latest state of the other side. The result is a stream of changes to the materialized view of the join between the two tables.`}</p>
    </blockquote>
    <h3><HashLink id="stream-faults" to="/data-intensive-apps#stream-faults" mdxType="HashLink">{`Fault tolerance`}</HashLink></h3>
    <p>{`There are techniques for achieving fault tolerance and exactly-once semantics in a stream processor.`}</p>
    <p>{`We need to discard the partial output of any failed tasks as we do with batch processing. But since a stream process is long-running and produces output continuously, we can’t simply discard all output.`}</p>
    <p>{`A fine-grained recovery mechanism can be used, based on "microbatching", checkpointing, transactions, or idempotent writes.`}</p>
    <h2><HashLink id="chapter-12" to="/data-intensive-apps#chapter-12" mdxType="HashLink">{`Chapter 12: The Future of Data Systems`}</HashLink></h2>
    <h3><HashLink id="derived-data" to="/data-intensive-apps#derived-data" mdxType="HashLink">{`Derived data`}</HashLink></h3>
    <p>{`Expressing dataflows as transformations from one dataset to another helps evolve applications.`}</p>
    <p>{`If you want to change one of the processing steps you can just re-run the new transformation code on the whole input dataset in order to re-derive the output.`}</p>
    <p>{`If something goes wrong you can fix the code and reprocess the data in order to recover.`}</p>
    <p>{`Derived state can further be observed by downstream consumers.`}</p>
    <h3><HashLink id="future-apps" to="/data-intensive-apps#future-apps" mdxType="HashLink">{`Asynchronous, fault tolerant and performant apps`}</HashLink></h3>
    <p>{`We can ensure our async event processing remains correct in the presence of faults. To accomplish this, we can use end-to-end request identifiers to make operations idempotent and we can check constraints asynchronously.`}</p>
    <p>{`Clients can either wait until the check has passed or go ahead without waiting but risk having to apologize about a constraint violation.`}</p>
    <p>{`This approach is much more scalable and robust than the traditional approach of using distributed transactions. It fits with how many business processes work in practice.`}</p>
    <p>{`This approach doesn't sacrifice performance, even in geographically distributed scenarios.`}</p>
    <p>{`We can use audits to verify the integrity of data and detect corruption.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      